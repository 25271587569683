import React from 'react';
import { TextField, Typography, Button } from "@material-ui/core"
import { useSelector, useDispatch } from "react-redux";
import {setAddedNote} from "./request_slice"

const NOTE = 'note';

const AddNote = () => {
  const dispatch = useDispatch();
  const { addedNote } = useSelector(state => state.request);

  const handleTextField = (event) => {
    dispatch(setAddedNote(event.target.value));
  }

  return (
    <React.Fragment>
      <Typography variant="subtitle1">
        Please share anything that will help prepare for our meeting.
      </Typography>
      <TextField
        id={NOTE}
        name={NOTE}
        variant="outlined"
        label=""
        fullWidth
        value={addedNote}
        onChange={handleTextField}
        margin="dense"
        multiline
        rowsMax="4"
      />
    </React.Fragment>
  );

}

export default AddNote;
