import React, { useEffect, useState } from "react"
import {
  Grid, TextField, Button, makeStyles, Typography, Paper
} from "@material-ui/core"
import PhoneInput from "react-phone-input-2"
import { useSelector, useDispatch } from "react-redux";
import {setContactInfo} from "./request_slice"
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"

const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const EMAIL = 'email';
const PHONE = 'phone';

const useStyles = makeStyles(theme => ({
  getStartedButton: {
    height: '48px',
    [theme.breakpoints.down('sm')]: {
      height: '56px'
    }
  },
  phoneInput: {
    // Need important to override the plugin's default style.
    height: '48px !important',
    width: '100% !important',
    [theme.breakpoints.down('sm')]: {
      height: '56px !important',
    }
  },
  gwxMemberPaper: {
    borderColor: theme.palette.primary,
    padding: theme.spacing(2)
  }
}));

const ContactInfo = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {contactInfo} = useSelector(state => state.request);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  // TextField errors are handled by the browser.
  const handleTextField = event => {
    dispatch(setContactInfo({
      key: event.target.name,
      value:event.target.value
    }));
  }
  const handlePhoneChange = (inputPhone) => {
    dispatch(setContactInfo({
      key: PHONE,
      value: inputPhone
    }));
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <TextField
          id={FIRST_NAME}
          name={FIRST_NAME}
          label="First Name"
          variant="outlined"
          fullWidth
          required
          value={contactInfo.firstName}
          onChange={handleTextField}
          margin="dense"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id={LAST_NAME}
          name={LAST_NAME}
          label="Last Name"
          variant="outlined"
          placeholder="Last Name"
          fullWidth
          required
          value={contactInfo.lastName}
          onChange={handleTextField}
          margin="dense"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id={EMAIL}
          name={EMAIL}
          variant="outlined"
          label="Email"
          type="email"
          fullWidth
          value={contactInfo.email}
          onChange={handleTextField}
          required
          margin="dense"
        />
      </Grid>
      <Grid item xs={12}>
        <PhoneInput
          inputProps={{
            name:"phone",
            id:"phone"
          }}
          inputClass={classes.phoneInput}
          onlyCountries={['us']}
          country={'us'}
          disableDropdown
          value={contactInfo.phone}
          onChange={handlePhoneChange}
          placeholder="test"
        />
      </Grid>
      <Grid item xs={12} align="center">
        <Typography variant="caption"><InfoOutlinedIcon style={{fontSize:'14px', marginTop: '-3px'}}/> We use your phone number to make sure you're a real person.</Typography>
      </Grid>
    </Grid>

  );
}

export default ContactInfo;
