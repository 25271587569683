import React, { useContext, useEffect, useState } from "react"
import {MixpanelContext} from '../../../../lib/tracker'
import {
  Container,
  Box,
  Typography,
  Paper, Chip, makeStyles, Stepper, Step, StepLabel, Grid, TextField,

  List,
  ListItem,
  ListItemIcon,
  ListItemText, Button, CircularProgress, CssBaseline, ThemeProvider,
} from "@material-ui/core"

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PersonIcon from '@material-ui/icons/Person';
import NoteIcon from '@material-ui/icons/Note';
import BeenhereIcon from '@material-ui/icons/Beenhere';

import Layout from '../../../layout';
import clsx from "clsx"
import DesktopTimePicker from "./desktop_time_picker"
import moment from "moment"
import axios from "axios"
import { API_ADDRESS, APP_BAR_STATE, createEnum, PHONE_REGEX } from "../../../../lib/constants"
import { ElementOrLoader } from "../../../util"
import ContactInfo from "./contact_info"
import AddNote from "./add_note"
import GetStartedPhone from "../../../get_started_phone"

import { useSelector, useDispatch } from "react-redux";
import { setSelectedTimeslot, setContactInfo } from "./request_slice"
import { getAuthToken, isAuthenticated } from "../../../../lib/auth"
import SharedStyles from '../../../shared_styles'
import SEO from "../../../seo"
import { setAppBar, setSnackbar, setSystemData } from "../system/system_slice"
import Util from "../../../../lib/util"
import {apiGet} from "../../../../lib/api"

const useStyles = makeStyles((theme) =>({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  centerElements: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  details: {
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',
    borderColor: theme.palette.divider
  },
  requestStatusPaper: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    backgroundImage:'linear-gradient(-339deg, #673AB7 50%, #9069d6 98%)',
    alignItems:'center',
  },
  internalPadding: {
    padding: theme.spacing(1)
  },
  green: {
    color: theme.palette.success.dark
  },
  red: {
    color: theme.palette.error.dark
  },
  profilePicContainer: {
    borderRadius: '50%',
    overflow: 'hidden',
    width: '75px',
    height: '75px'
  },
  nextButton: {
    marginTop: theme.spacing(2)
  }
}));

const STEPS = createEnum(['TIME', 'CONTACT', 'NOTE', 'SENDING', 'SENT'], 0);


const UserCalendar = ({navigate, userHandle, params, location, inApp, userId}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const styles = SharedStyles();
  const mixpanel = useContext(MixpanelContext);

  const {selectedTimeslot, contactInfo, addedNote} = useSelector(state => state.request);
  const {user} = useSelector(state => state.system);

  const [showBezel, setShowBezel] = useState(false);

  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [userName, setUserName] = useState("");
  const [timeslotsMap, setTimeslotsMap] = useState({});
  const [activeStep, setActiveStep] = useState(STEPS.TIME);
  const [localIsAuthenticated, setLocalIsAuthenticated] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);

  const [urlBase, setUrlBase] = useState('');

  useEffect(()=>{
    // Disable refetching data and setting state if the page is already loaded.
    if (!loading) {
      return;
    }

    // if (!inApp && isAuthenticated()) {
    //   navigate(`/app/profile/schedule/${userId}`)
    //   return;
    // }
    if (inApp) {
      dispatch(setAppBar({
        state: APP_BAR_STATE.BACK_LABEL,
        label: 'Schedule Time'
      }));
    }
    setShowBezel(!inApp);

    setLocalIsAuthenticated(isAuthenticated());

    let startDate = moment();
    let endDate = moment(startDate).add(2, 'week');
    const startDateString = startDate.utc().format();
    const endDateString = endDate.utc().format();
    const userParams = {
      start:startDateString,
      end: endDateString
    };

    if (inApp) {
      userParams['id'] = userId;
      setUrlBase(`/app/network/schedule/${userId}`);
    } else {
      userParams['handle'] = userHandle;
      setUrlBase(`/x/${userHandle}`);
    }

    apiGet('request/user-timeslots', userParams)
      .then((response) => {
        if(response.error) {
          throw '';
        }
        setUserData(response.user);
        setUserName(`${response.user.first_name} ${response.user.last_name}`);

        let localTimeSlotsMap = {};
        response.timeslots.forEach((timeslot) => {
          const date = moment(timeslot).format('YYYY-MM-DD');
          if (!localTimeSlotsMap[date]) {
            localTimeSlotsMap[date] = [];
          }
          localTimeSlotsMap[date].push(timeslot)
        });
        setTimeslotsMap(localTimeSlotsMap);
        return response.timeslots;
      })
      .then((timeslots) => {
        // Verify the time param is valid for this user. If it is, skip the time
        // selection.
        let skipToContact = false;
        let hasAccount = false;
        if (params.time)  {
          const time = moment(params.time);
          if (time.isValid()) {
            for (const slot of timeslots) {
              if (slot == params.time)  {
                dispatch(setSelectedTimeslot(slot));
                skipToContact = true;
                break;
              }
            }
          }
        }
        // Get user's name and set hasAccount if the user is logged in.
        if (isAuthenticated()) {
          setLoginLoading(true);
          const config = {
            headers: { Authorization: `Bearer ${getAuthToken()}` }
          };
          return apiGet('user/info')
            .then((response)=> {
              if (response.error) {
                return;
              }

              dispatch(setContactInfo({
                key: 'firstName',
                value: response.info.first_name
              }));
              dispatch(setContactInfo({
                key: 'lastName',
                value: response.info.last_name
              }));
              dispatch(setContactInfo({
                key: 'hasAccount',
                value: true
              }));
              if (skipToContact) {
                setActiveStep(STEPS.NOTE)
              }
            })
            .finally(()=>setLoginLoading(false));
        } else {
          if (skipToContact) {
            setActiveStep(STEPS.CONTACT)
          }
        }
      })
      .finally(()=>setLoading(false));

  }, [params])

  const getDetailsView = () => {
    let listItems = [];
    if (activeStep >= STEPS.CONTACT && selectedTimeslot) {
      const dateObj = moment(selectedTimeslot);
      const start = dateObj.format('hh:mma');
      const end = dateObj.add(30,'minutes').format('h:mma');
      const dateStr = dateObj.format('dddd, MMM D YYYY');
      const fullString = `${start} - ${end} ${Util.getTimezone()}, ${dateStr}`;
      listItems.push(
        <ListItem key='datetime'>
          <ListItemIcon>
            <CalendarTodayIcon />
          </ListItemIcon>
          <ListItemText primary={fullString} />
        </ListItem>

      );
    }

    if (activeStep >= STEPS.NOTE) {
      listItems.push(
        <ListItem key='contact'>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary={contactInfo.firstName+' '+contactInfo.lastName} />
        </ListItem>

      );
    }

    if (activeStep >= STEPS.SENDING){
      listItems.push(
        <ListItem key='note'>
          <ListItemIcon>
            <NoteIcon />
          </ListItemIcon>
          <ListItemText primary={addedNote || 'No Note'} />
        </ListItem>

      );
    }

    let sentStatus;
    if (activeStep >= STEPS.SENT) {
      sentStatus = (
        <Paper variant="outlined" className={clsx([classes.acceptedPaper, classes.centerElements, classes.internalPadding])}>
          <BeenhereIcon className={classes.green}/>
          <Typography variant="h5" className={classes.white}>
            Your Request was Sent
          </Typography>
          <Typography variant="subtitle1" className={classes.white}>
            You'll get a text when we get a response from {userName}.
          </Typography>

        </Paper>
      )
      // sentStatus = (
      //   <Paper elevation={0} className={classes.requestStatusPaper}>
      //     <BeenhereIcon className={classes.white}/>
      //     <Typography variant="h5" className={classes.white}>
      //       Your Request was Sent
      //     </Typography>
      //     <Typography variant="subtitle1" className={classes.white}>
      //       You'll get a text when we get a response from {userName}.
      //     </Typography>
      //
      //   </Paper>
      // );
    }



    return (
      <React.Fragment>
        <List>
          {listItems}
        </List>
        {sentStatus}
      </React.Fragment>
    );
  }

  const getUpsellView = () => {
    let getStartedProps = {};
    if (!localIsAuthenticated) {
      getStartedProps['phoneDefault'] = contactInfo.phone;
    } else {
      return null;
    }

    return (
      <React.Fragment>
        <Typography variant="subtitle1">
          Join Greenwoodx and get introduced to your next amazing connection
        </Typography>
        <Grid container style={{width:'100%'}}>
          <GetStartedPhone
            {...getStartedProps}
            fullWidth/>
        </Grid>
      </React.Fragment>
    );
  }


  const getStepLabel = (index) => {
    switch(index) {
      case STEPS.TIME:
        return "Select a Date & Time";
      case STEPS.CONTACT:
        return "Your Contact Details";
      case STEPS.NOTE:
        return "Add a Note (Optional)";
      case STEPS.SENDING:
        return 'Sending';
      case STEPS.SENT:
        return null;
    }

  }

  const getLoginOrElement = () => {
    return (
      <React.Fragment>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            navigate(`/login?url=/x/${userHandle}?time=${selectedTimeslot}`);
          }}
        >
          {loginLoading? <CircularProgress /> : "Login to Greenwoodx"}
        </Button>
        <Typography variant="subtitle2">Or</Typography>
      </React.Fragment>

    );
  }

  const getStepPage = (index) => {
    switch(index) {
      case STEPS.TIME:
        return (
          <DesktopTimePicker
            timeslotsMap={timeslotsMap}
            onConfirmTimeslot={gotoNextStep}
          />
        );
      case STEPS.CONTACT:
        return (
          <React.Fragment>
            {getLoginOrElement()}
            <ContactInfo />
          </React.Fragment>
        );
      case STEPS.NOTE:
        return (
          <AddNote />
        );
      case STEPS.SENDING:
        return (
          <CircularProgress size={24} />
        );
      case STEPS.SENT:
        return getUpsellView();
    }
  }

  const gotoNextStep = () => {
    if (activeStep == STEPS.TIME) {
      navigate(`${urlBase}?time=${selectedTimeslot}`, {state:{skipSecondLoad: true}});
      if (contactInfo.hasAccount) {
        return setActiveStep(STEPS.NOTE);
      } else {
        return setActiveStep(activeStep+1);
      }
      return;
    }

    if (activeStep == STEPS.CONTACT) {
      // Validation.
      const cleanPhone = contactInfo.phone.replace(/[^0-9|+]/g, '');
      if (!PHONE_REGEX.test(cleanPhone)) {
        return alert("Please enter a valid phone number.");
      }
    }
    if (activeStep == STEPS.NOTE) {
      submitRequest();
    }
    setActiveStep(activeStep+1);
  }

  const submitRequest = () => {

    // Send request.
    const config = {};
    const data = {
      timeslot: selectedTimeslot,
      contact_info: {
        has_account: contactInfo.hasAccount
      },
      note: addedNote
    };

    if (inApp) {
      data['id'] = userId;
    } else {
      data['handle'] = userHandle;
    }

    if (!contactInfo.hasAccount) {
      data.contact_info['first_name'] = contactInfo.firstName;
      data.contact_info['last_name'] = contactInfo.lastName;
      data.contact_info['email'] = contactInfo.email;

      const cleanPhone = contactInfo.phone.replace(/[^0-9|+]/g, '');
      data.contact_info['phone'] = cleanPhone;
    } else {
      config['headers'] = { Authorization: `Bearer ${getAuthToken()}` };
    }

    setActiveStep(STEPS.SENDING);
    axios.post(`${API_ADDRESS}/request/send-user-request`, data, config)
      .then((response)=> {
        if (response.data.error) {
          alert(response.data.error_message);
          setActiveStep(STEPS.NOTE);
          return;
        }
        mixpanel.track('Direct Request', {
          'Request': response.data.request_id,
          'From': user.id,
          'To': data['id']
        });
        setActiveStep(STEPS.SENT);
        // Refresh user requests.
        if (isAuthenticated()) {
          dispatch(setSystemData({ key: 'userRequests', value: response.data.user_requests}));
          dispatch(setSnackbar({
            open: true,
            text: 'Your request has been sent 🎉'
          }))
          return navigate('/app');
        }
      })
      .catch(()=>{
        alert('There was a problem reaching the server. Try again please.');
      })
  }

  const getNextButton = () => {
    if (activeStep == STEPS.TIME || activeStep >= STEPS.SENDING) return null;
    let label;
    switch(activeStep) {
      case STEPS.CONTACT:
        label = "Next";
        break;
      case STEPS.NOTE:
        label = "Send Request"
        break;
    }

    return (
      <Button
        className={classes.nextButton}
        onClick={gotoNextStep}
        color="primary"
        variant="contained">
        {label}
      </Button>
    );
  }

  return (
    <Layout showFooter={false} showHeader={showBezel}>
      <CssBaseline />
      <Container maxWidth="sm">
        <ElementOrLoader loading={loading}>
          <SEO title={`Schedule time with ${userName}`} />
          <Paper className={classes.paper} elevation={1} >
            <Box className={clsx([classes.details])} borderBottom={1}>
              <Box className={classes.centerElements}>
                {showBezel &&
                  <Typography variant="subtitle2" color="primary">
                    Schedule Time
                  </Typography>
                }
                <Box className={classes.profilePicContainer}>
                  <img style={{width: '100%'}} src={userData.profile_pic} />
                </Box>
                <Typography variant="h5" className={classes.centerText}>
                  {userName}'s Calendar
                </Typography>
              </Box>
              {getDetailsView()}
            </Box>
            <Box className={classes.centerElements}>
              <Typography variant="h6">
                {getStepLabel(activeStep)}
              </Typography>
              {getStepPage(activeStep)}
              {getNextButton()}
            </Box>
          </Paper>
        </ElementOrLoader>
      </Container>
    </Layout>
  );
}

export default UserCalendar;
