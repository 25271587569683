import React, {useState, useEffect} from 'react';
import {
  Grid,
  Box,
  Button, Chip, Container, CssBaseline, makeStyles, Paper, Typography, ListItemIcon, ListItemText, ListItem,
} from "@material-ui/core"
import Calendar from 'react-calendar';
import './react_calendar_style.css';
import moment from 'moment';
import { ElementOrLoader } from "../../../util"
import { useSelector, useDispatch } from "react-redux";
import {sendRequest} from "../../../../lib/api";
import { setRequestTopic, setSelectedTimeslot } from "./request_slice"

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import clsx from 'clsx';
import Util from "../../../../lib/util"

const useStyles = makeStyles((theme) =>({
  timeSelectGrid: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  calendarGrid: {
    justifyContent: 'center'
  },
  dateHasTimeslots: {
    border: '2px solid white !important;',
    backgroundColor: theme.palette.primary.main +'26',
    color: theme.palette.primary.main

  },
  dateHasNoTimeslots: {

  },
  timeslotButtonRow: {
    marginBottom: theme.spacing(1)
  },
  timeslotButton: {
    height: '48px',
    [theme.breakpoints.down('sm')]: {
      height: '56px'
    },
  },
  timeslotButtonFullWidth: {
    width: '100%'
  },
  timeslotButtonHalfWidth: {
    width: 'calc(50% - 5px)'
  },
}));

const DesktopTimePicker = ({timeslotsMap, onConfirmTimeslot}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {selectedTimeslot} = useSelector(state => state.request);

  const [date, setDate] = useState(null);
  const [timeSlots, setTimeSlots] = useState(null);


  // Custom Calendar overrides.
  const selectDate = (newDate, event) => {
    setDate(newDate);
    selectTimeSlot(null);
    const dateKey = moment(newDate).format('YYYY-MM-DD');
    setTimeSlots(timeslotsMap[dateKey]);
  }

  const tileDisabled = ({date}) => !timeslotsMap[
    moment(date).format('YYYY-MM-DD')]

  const tileClassName = ({date}) => {
    // Show indicator if this day has timeslots
    if(timeslotsMap[moment(date).format('YYYY-MM-DD')]) {
      return classes.dateHasTimeslots;
    } else {
      return classes.dateHasNoTimeslots;
    }
  }

  const selectTimeSlot = (timeslot) => {
    dispatch(setSelectedTimeslot(timeslot));
  }

  const getTimeSlots = () => {
    if (!timeSlots) {
      return null;
    }
    let slotElements = timeSlots.sort().map((slot) => {
      let confirmButton = null;
      let timeSlotClass = classes.timeslotButtonFullWidth;
      if (selectedTimeslot && slot == selectedTimeslot) {
        timeSlotClass = classes.timeslotButtonHalfWidth;
        confirmButton = (
          <Button
            variant="contained"
            color="primary"
            onClick={onConfirmTimeslot}
            className={clsx([classes.timeslotButton, timeSlotClass])}
          >
            Confirm
          </Button>
        );
      }
      return (
        <Box
          key={slot}
          className={classes.timeslotButtonRow}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={()=>selectTimeSlot(slot)}
            className={clsx([classes.timeslotButton, timeSlotClass])}
            style={{marginRight:'10px'}}
          >
            {moment(slot).format("hh:mma")} ({Util.getTimezone()})
          </Button>
          {confirmButton}
        </Box>
      );
    });

    return (
      <Grid item xs={12}  sm={5} className={classes.timeSelectGrid}>
        <Typography variant="subtitle1">{moment(date).format("dddd, MMM D")}</Typography>
        <Grid item alignItems="center" style={{marginBottom: '15px'}}>
          <AccessTimeIcon  style={{fontSize: '14px'}}/>
          <Typography variant="caption">All times are in {Util.getTimezone()}</Typography>
        </Grid>
        {slotElements}
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} className={classes.calendarGrid}>
      <Grid item xs={12} sm={7} >
        <ElementOrLoader loading={!timeslotsMap}>
          <Calendar
            calendarType="US"
            view="month"
            maxDetail="month"
            minDetail="month"
            prevLabel={<NavigateBeforeIcon />}
            nextLabel={<NavigateNextIcon />}
            onClickDay={selectDate}
            value={date}
            // tileContent={tileContent}
            tileDisabled={tileDisabled}
            tileClassName={tileClassName}
          />
        </ElementOrLoader>
      </Grid>
      {getTimeSlots()}
    </Grid>
  );
}

export default DesktopTimePicker;
